import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthEntity } from '@dendra/entity-auth';
import { filter, map, switchMap, take } from 'rxjs';

/**
 * Permission guard to check if user has access to a role, specified in the `has_access` data param.
 *
 * If `has_access` is not specified (i.e., no specific role is required), then returns true.
 */
export const rolePermissionGuard: CanActivateFn = (next, state) => {
  const auth = inject(AuthEntity);
  const router = inject(Router);

  if (next.data.has_access === undefined) {
    return true;
  }

  return auth.query.isLoading$.pipe(
    filter(loading => loading === false),
    take(1),
    switchMap(() => auth.query.canAccess(next.data.has_access)),
    map(hasAccess => {
      if (hasAccess) {
        return true;
      }
      router.navigate(['/not-permitted']);
      return false;
    }),
  );
};
