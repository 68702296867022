import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthEntity } from '@dendra/entity-auth';
import { filter, map, take } from 'rxjs';

export const loginRequiredGuard: CanActivateFn = () => {
  const auth = inject(AuthEntity);
  const router = inject(Router);

  return auth.query.selectLoading().pipe(
    filter(loading => !loading),
    take(1),
    map(() => {
      if (auth.query.getValue().username) {
        return true;
      }
      router.navigate(['/login'], {
        state: {
          loginRedirect: encodeURIComponent(
            `${window.location.pathname}${window.location.search}`,
          ),
        },
      });
      return false;
    }),
  );
};
