import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthEntity } from '@dendra/entity-auth';
import { filter, map, take } from 'rxjs';

export const orgMembershipRequiredGuard: CanActivateFn = (next, state) => {
  const auth = inject(AuthEntity);
  const router = inject(Router);

  return auth.query.selectLoading().pipe(
    filter(loading => !loading),
    take(1),
    map(() => {
      const profile = auth.query.getValue();
      if (profile.organisation || profile.isInternal) {
        return true;
      }
      router.navigate(['/login/upgrade-required']);
      return false;
    }),
  );
};
